export const AVERAGE_PERCENTAGES_OF_REWARDS = [
  {
    id: 1,
    value: 0.01,
    name: "1%",
  },
  {
    id: 2,
    value: 0.02,
    name: "2%",
  },
  {
    id: 3,
    value: 0.03,
    name: "3%",
  },
  {
    id: 4,
    value: 0.04,
    name: "4%",
  },
  {
    id: 5,
    value: 0.05,
    name: "5%",
  },
  {
    id: 6,
    value: 0.06,
    name: "6%",
  },
  {
    id: 7,
    value: 0.07,
    name: "7%",
  },
  {
    id: 8,
    value: 0.08,
    name: "8%",
  },
  {
    id: 9,
    value: 0.09,
    name: "9%",
  },
  {
    id: 10,
    value: 0.1,
    name: "10%",
  },
  {
    id: 11,
    value: 0.11,
    name: "11%",
  },
  {
    id: 12,
    value: 0.12,
    name: "12%",
  },
  {
    id: 13,
    value: 0.13,
    name: "13%",
  },
  {
    id: 14,
    value: 0.14,
    name: "14%",
  },
  {
    id: 15,
    value: 0.15,
    name: "15%",
  },
  {
    id: 16,
    value: 0.16,
    name: "16%",
  },
  {
    id: 17,
    value: 0.17,
    name: "17%",
  },
  {
    id: 18,
    value: 0.18,
    name: "18%",
  },
  {
    id: 19,
    value: 0.19,
    name: "19%",
  },
  {
    id: 20,
    value: 0.2,
    name: "20%",
  },
  {
    id: 21,
    value: 0.21,
    name: "21%",
  },
  {
    id: 22,
    value: 0.22,
    name: "22%",
  },
  {
    id: 23,
    value: 0.23,
    name: "23%",
  },
  {
    id: 24,
    value: 0.24,
    name: "24%",
  },
  {
    id: 25,
    value: 0.25,
    name: "25%",
  },
];
