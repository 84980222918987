export const INCOME_TYPES = [
  {
    id: 1,
    name: "Подключение тарифов Промо",
    type: "salesPromo",
    value: {
      value: "",
    },
  },
  {
    id: 2,
    name: "Подключение тарифов Про+",
    type: "salesPro",
    value: {
      value: "",
    },
  },
  {
    id: 3,
    name: "Продажа RCP1",
    type: "RCP1",
    value: {
      sales: "",
      averageTurnover: "",
    },
  },
  {
    id: 4,
    name: "Транзакции RC POS",
    type: "transactionsRCP4",
    value: {
      connected: "",
      averageTurnover: "",
    },
  },
  {
    id: 5,
    name: "Программа лояльности",
    type: "rewards",
    value: {
      connected: "",
      averageTurnover: "",
      selectedAveragePercentageOfReward: null,
    },
  },
];
