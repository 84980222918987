<script>
export default {};
</script>

<template>
  <div class="calculator-tsp-income-add-month" @click="$emit('click')">
    <p>Добавить месяц</p>

    <span class="icon-plus calculator-tsp-income-add-month__icon"></span>
  </div>
</template>
<style lang="scss" scoped>
.calculator-tsp-income-add-month {
  padding: 16px 24px;
  background-color: $light-primary;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    @include text-2;
    color: $dark-fifth;
    margin-right: 8px;
  }

  &__icon {
    width: 16px;
    height: 16px;
    background-color: $dark-fifth;
  }
}
</style>
