<script>
import MainTabs from "@/components/helpers/MainTabs";
import MainChart from "@/components/helpers/MainChart";

export default {
  components: { MainTabs, MainChart },

  props: {
    months: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedTab: {
        id: "total",
        title: "Всего - RC",
      },
      tabs: [
        {
          id: "total",
          title: "Всего - RC",
        },
        {
          id: "personal",
          title: "- RC",
          icon: "icon-person",
        },
        {
          id: "fromNetwork",
          title: "- RC",
          icon: "icon-line",
        },
      ],

      pieChartOptions: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        // Promo, Pro+, RCP1, trans. RCP1, trans. RCP4, rewards
        color: [
          "#FFBCD0",
          "#B2A6FA",
          "#D4F780",
          "#9EE5FF",
          "#DCDCDC",
          "#FFDA7A",
        ],
        legend: {
          orient: "vertical",
          left: "left",
          data: [
            "Продажа Промо",
            "Продажа Про+",
            "Продажа RCP1",
            "Транзакции RCP1",
            "Транзакции RC POS",
            "Программа лояльности",
          ],
          textStyle: {
            fontSize: 14,
            fontFamily: "Montserrat, sans-serif",
          },
          itemGap: 12,
        },
        series: [
          {
            name: "Заработок за год",
            type: "pie",
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
              fontSize: 20,
              fontWeight: "bold",
              emphasis: {
                show: true,
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
      barChartOptions: {
        color: "#68C783",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: [
            "1 мес.",
            "2 мес.",
            "3 мес.",
            "4 мес.",
            "5 мес.",
            "6 мес.",
            "7 мес.",
            "8 мес.",
            "9 мес.",
            "10 мес.",
            "11 мес.",
            "12 мес.",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [],
            type: "bar",
          },
        ],
      },

      innerWidth: window.innerWidth,
    };
  },

  watch: {
    innerWidth() {
      this.setChartsOptions();
    },

    months: {
      deep: true,
      handler() {
        this.createUnitDataPieChart("personal");
        this.createUnitDataBarDiagram("personal");
        this.createUnitDataPieChart("fromNetwork");
        this.createUnitDataBarDiagram("fromNetwork");
        this.createTotalDataBarDiagram();
        this.createTotalDataPieChart();
        this.selectedTab = {
          id: "total",
          title: "Всего - RC",
        };
      },
    },

    selectedTab: {
      deep: true,
      handler() {
        this.switchCarts();
      },
    },
  },

  methods: {
    setChartsOptions() {
      if (this.innerWidth < 1141) {
        // pie chart
        this.pieChartOptions.legend.left = "left";
        this.pieChartOptions.legend.bottom = "0";
        this.pieChartOptions.legend.top = "auto";
        this.pieChartOptions.legend.right = "auto";

        this.pieChartOptions.series[0].top = "0";
        this.pieChartOptions.series[0].bottom = "35%";
        this.pieChartOptions.series[0].left = "0%";
        this.pieChartOptions.series[0].right = "auto";
        this.pieChartOptions.series[0].radius = ["55%", "85%"];

        // bar chart
        this.barChartOptions.yAxis.type = "category";
        this.barChartOptions.yAxis.data = [
          "1 мес.",
          "2 мес.",
          "3 мес.",
          "4 мес.",
          "5 мес.",
          "6 мес.",
          "7 мес.",
          "8 мес.",
          "9 мес.",
          "10 мес.",
          "11 мес.",
          "12 мес.",
        ];
        this.barChartOptions.xAxis.type = "value";
      } else {
        // pie chart
        this.pieChartOptions.legend.left = "auto";
        this.pieChartOptions.legend.bottom = "center";
        this.pieChartOptions.legend.top = "center";
        this.pieChartOptions.legend.right = "25";

        this.pieChartOptions.series[0].top = "auto";
        this.pieChartOptions.series[0].bottom = "auto";
        this.pieChartOptions.series[0].radius = ["60%", "90%"];

        // bar chart
        this.barChartOptions.xAxis.type = "category";
        this.barChartOptions.xAxis.data = [
          "1 мес.",
          "2 мес.",
          "3 мес.",
          "4 мес.",
          "5 мес.",
          "6 мес.",
          "7 мес.",
          "8 мес.",
          "9 мес.",
          "10 мес.",
          "11 мес.",
          "12 мес.",
        ];
        this.barChartOptions.yAxis.type = "value";
      }
    },

    switchCarts() {
      switch (this.selectedTab.id) {
        case "total": {
          this.createTotalDataBarDiagram();
          this.createTotalDataPieChart();
          break;
        }
        case "personal": {
          this.createUnitDataBarDiagram("personal");
          this.createUnitDataPieChart("personal");
          break;
        }
        case "fromNetwork": {
          this.createUnitDataBarDiagram("fromNetwork");
          this.createUnitDataPieChart("fromNetwork");
        }
      }
    },

    createUnitDataPieChart(unitName) {
      this.pieChartOptions.series[0].data = [];

      let Pro = 0;
      let Promo = 0;
      let RCP1Profit = 0;
      let currentRCP1 = 0;
      let previousRCP1 = 0;
      let currentTransactionRCP4 = 0;
      let previousTransactionRCP4 = 0;
      let currentRewards = 0;
      let previousRewards = 0;
      let totalSum = 0;

      let proValue = unitName === "personal" ? 13650 : 439;
      let promoValue = unitName === "personal" ? 5040 : 135;
      let RCP1ProfitValue = unitName === "personal" ? 1000 : 100;
      let RCP1Value = unitName === "personal" ? 0.001 : 0.0001;
      let RCP1ToFixed = unitName === "personal" ? 3 : 4;
      let transactionRCP4Value = unitName === "personal" ? 0.001 : 0.0001;
      let transactionRCP4ToFixed = unitName === "personal" ? 3 : 4;
      let rewardsValue = unitName === "personal" ? 0.1 : 0.0125;
      let rewardsToFixed = unitName === "personal" ? 1 : 4;

      this.months.forEach((month, index) => {
        previousRCP1 += currentRCP1;
        previousTransactionRCP4 += currentTransactionRCP4;
        previousRewards += currentRewards;

        if (month[unitName] && Object.keys(month[unitName]).length > 0) {
          if (month[unitName].hasOwnProperty("salesPromo")) {
            Promo =
              Promo +
              (month[unitName].salesPromo
                ? month[unitName].salesPromo.value
                : 0) *
                promoValue;
          }

          if (month[unitName].hasOwnProperty("salesPro")) {
            Pro =
              Pro +
              (month[unitName].salesPro ? month[unitName].salesPro.value : 0) *
                proValue;
          }

          if (month[unitName].hasOwnProperty("RCP1")) {
            RCP1Profit =
              RCP1Profit + month[unitName].RCP1.sales * RCP1ProfitValue;
          }

          if (month[unitName].hasOwnProperty("RCP1")) {
            currentRCP1 =
              currentRCP1 +
              Number(
                (
                  month[unitName].RCP1.sales *
                  month[unitName].RCP1.averageTurnover *
                  RCP1Value
                ).toFixed(RCP1ToFixed)
              );
          }

          if (month[unitName].hasOwnProperty("transactionsRCP4")) {
            currentTransactionRCP4 =
              currentTransactionRCP4 +
              Number(
                (
                  month[unitName].transactionsRCP4.connected *
                  month[unitName].transactionsRCP4.averageTurnover *
                  transactionRCP4Value
                ).toFixed(transactionRCP4ToFixed)
              );
          }

          if (month[unitName].hasOwnProperty("rewards")) {
            let value = month[unitName].rewards
              .selectedAveragePercentageOfReward
              ? month[unitName].rewards.selectedAveragePercentageOfReward.value
              : 0;

            currentRewards =
              currentRewards +
              Number(
                (
                  month[unitName].rewards.connected *
                  month[unitName].rewards.averageTurnover *
                  value *
                  rewardsValue
                ).toFixed(rewardsToFixed)
              );
          }
        }
      });

      if (this.months.length > 0 && this.months.length < 12) {
        for (let i = this.months.length; i < 12; i++) {
          previousRCP1 += currentRCP1;
          previousTransactionRCP4 += currentTransactionRCP4;
          previousRewards += currentRewards;
        }
      }

      totalSum =
        Pro +
        Promo +
        RCP1Profit +
        previousRCP1 +
        previousTransactionRCP4 +
        previousRewards;

      this.tabs.forEach((item) => {
        if (item.id === unitName) {
          item.title = totalSum > 0 ? `${totalSum.toFixed(0)} RC` : `0 RC`;
        }
      });

      this.pieChartOptions.series[0].data = [
        { value: Promo, name: "Продажа Промо" },
        { value: Pro, name: "Продажа Про+" },
        { value: RCP1Profit, name: "Продажа RCP1" },
        { value: previousRCP1, name: "Транзакции RCP1" },
        { value: previousTransactionRCP4, name: "Транзакции RC POS" },
        { value: previousRewards, name: "Вознаграждения" },
      ];
    },

    createUnitDataBarDiagram(unitName) {
      this.barChartOptions.series[0].data = [];

      let Pro = 0;
      let Promo = 0;
      let RCP1Profit = 0;
      let currentRCP1 = 0;
      let previousRCP1 = 0;
      let currentTransactionRCP4 = 0;
      let previousTransactionRCP4 = 0;
      let currentRewards = 0;
      let previousRewards = 0;

      let proValue = unitName === "personal" ? 13650 : 439;
      let promoValue = unitName === "personal" ? 5040 : 135;
      let RCP1ProfitValue = unitName === "personal" ? 1000 : 100;
      let RCP1Value = unitName === "personal" ? 0.001 : 0.0001;
      let RCP1ToFixed = unitName === "personal" ? 3 : 4;
      let transactionRCP4Value = unitName === "personal" ? 0.001 : 0.0001;
      let transactionRCP4ToFixed = unitName === "personal" ? 3 : 4;
      let rewardsValue = unitName === "personal" ? 0.1 : 0.0125;
      let rewardsToFixed = unitName === "personal" ? 1 : 4;

      this.months.forEach((month, index) => {
        Pro = 0;
        Promo = 0;
        RCP1Profit = 0;

        previousRCP1 = currentRCP1;
        previousTransactionRCP4 = currentTransactionRCP4;
        previousRewards = currentRewards;

        if (month[unitName] && Object.keys(month[unitName]).length > 0) {
          if (month[unitName].hasOwnProperty("salesPromo")) {
            Promo =
              Promo +
              (month[unitName].salesPromo
                ? month[unitName].salesPromo.value
                : 0) *
                promoValue;
          }

          if (month[unitName].hasOwnProperty("salesPro")) {
            Pro =
              Pro +
              (month[unitName].salesPro ? month[unitName].salesPro.value : 0) *
                proValue;
          }

          if (month[unitName].hasOwnProperty("RCP1")) {
            RCP1Profit =
              RCP1Profit + month[unitName].RCP1.sales * RCP1ProfitValue;
          }

          if (month[unitName].hasOwnProperty("RCP1")) {
            currentRCP1 =
              currentRCP1 +
              Number(
                (
                  month[unitName].RCP1.sales *
                  month[unitName].RCP1.averageTurnover *
                  RCP1Value
                ).toFixed(RCP1ToFixed)
              );
          }

          if (month[unitName].hasOwnProperty("transactionsRCP4")) {
            currentTransactionRCP4 =
              currentTransactionRCP4 +
              Number(
                (
                  month[unitName].transactionsRCP4.connected *
                  month[unitName].transactionsRCP4.averageTurnover *
                  transactionRCP4Value
                ).toFixed(transactionRCP4ToFixed)
              );
          }

          if (month[unitName].hasOwnProperty("rewards")) {
            let value = month[unitName].rewards
              .selectedAveragePercentageOfReward
              ? month[unitName].rewards.selectedAveragePercentageOfReward.value
              : 0;

            currentRewards =
              currentRewards +
              Number(
                (
                  month[unitName].rewards.connected *
                  month[unitName].rewards.averageTurnover *
                  value *
                  rewardsValue
                ).toFixed(rewardsToFixed)
              );
          }

          this.barChartOptions.series[0].data[index] =
            Pro +
            Promo +
            RCP1Profit +
            previousRCP1 +
            previousTransactionRCP4 +
            previousRewards;
        } else {
          this.barChartOptions.series[0].data[index] =
            previousRCP1 + previousTransactionRCP4 + previousRewards;
        }
      });

      if (this.months.length > 0 && this.months.length < 12) {
        for (let i = this.months.length; i < 12; i++) {
          this.barChartOptions.series[0].data[i] =
            currentRCP1 + currentTransactionRCP4 + currentRewards;
        }
      }
    },

    createTotalDataPieChart() {
      this.pieChartOptions.series[0].data = [];

      let Pro = 0;
      let Promo = 0;
      let RCP1Profit = 0;
      let currentRCP1 = 0;
      let previousRCP1 = 0;
      let currentTransactionRCP4 = 0;
      let previousTransactionRCP4 = 0;
      let currentRewards = 0;
      let previousRewards = 0;

      this.months.forEach((month, index) => {
        previousRCP1 += currentRCP1;
        previousTransactionRCP4 += currentTransactionRCP4;
        previousRewards += currentRewards;

        for (let key in month) {
          let proValue = key === "personal" ? 13650 : 439;
          let promoValue = key === "personal" ? 5040 : 135;
          let RCP1ProfitValue = key === "personal" ? 1000 : 100;
          let RCP1Value = key === "personal" ? 0.001 : 0.0001;
          let RCP1ToFixed = key === "personal" ? 3 : 4;
          let transactionRCP4Value = key === "personal" ? 0.001 : 0.0001;
          let transactionRCP4ToFixed = key === "personal" ? 3 : 4;
          let rewardsValue = key === "personal" ? 0.1 : 0.0125;
          let rewardsToFixed = key === "personal" ? 1 : 4;

          if (month[key].hasOwnProperty("salesPromo")) {
            Promo =
              Promo +
              (month[key].salesPromo ? month[key].salesPromo.value : 0) *
                promoValue;
          }

          if (month[key].hasOwnProperty("salesPro")) {
            Pro =
              Pro +
              (month[key].salesPro ? month[key].salesPro.value : 0) * proValue;
          }

          if (month[key].hasOwnProperty("RCP1")) {
            RCP1Profit = RCP1Profit + month[key].RCP1.sales * RCP1ProfitValue;
          }

          if (month[key].hasOwnProperty("RCP1")) {
            currentRCP1 =
              currentRCP1 +
              Number(
                (
                  month[key].RCP1.sales *
                  month[key].RCP1.averageTurnover *
                  RCP1Value
                ).toFixed(RCP1ToFixed)
              );
          }

          if (month[key].hasOwnProperty("transactionsRCP4")) {
            currentTransactionRCP4 =
              currentTransactionRCP4 +
              Number(
                (
                  month[key].transactionsRCP4.connected *
                  month[key].transactionsRCP4.averageTurnover *
                  transactionRCP4Value
                ).toFixed(transactionRCP4ToFixed)
              );
          }

          if (month[key].hasOwnProperty("rewards")) {
            let value = month[key].rewards.selectedAveragePercentageOfReward
              ? month[key].rewards.selectedAveragePercentageOfReward.value
              : 0;

            currentRewards =
              currentRewards +
              Number(
                (
                  month[key].rewards.connected *
                  month[key].rewards.averageTurnover *
                  value *
                  rewardsValue
                ).toFixed(rewardsToFixed)
              );
          }
        }
      });

      // установка значений диаграммы, если заполнены не все месяца
      if (this.months.length > 0 && this.months.length < 12) {
        for (let i = this.months.length; i < 12; i++) {
          previousRCP1 += currentRCP1;
          previousTransactionRCP4 += currentTransactionRCP4;
          previousRewards += currentRewards;
        }
      }

      this.pieChartOptions.series[0].data = [
        { value: Promo, name: "Продажа Промо" },
        { value: Pro, name: "Продажа Про+" },
        { value: RCP1Profit, name: "Продажа RCP1" },
        { value: previousRCP1, name: "Транзакции RCP1" },
        { value: previousTransactionRCP4, name: "Транзакции RC POS" },
        { value: previousRewards, name: "Программа лояльности" },
      ];
    },

    createTotalDataBarDiagram() {
      this.barChartOptions.series[0].data = [];

      let Pro = 0;
      let Promo = 0;
      let RCP1Profit = 0;
      let currentRCP1 = 0;
      let previousRCP1 = 0;
      let currentTransactionRCP4 = 0;
      let previousTransactionRCP4 = 0;
      let currentRewards = 0;
      let previousRewards = 0;
      let totalSum = 0;

      this.months.forEach((month, index) => {
        let monthTotalSum = 0;
        Pro = 0;
        Promo = 0;
        RCP1Profit = 0;

        previousRCP1 = currentRCP1;
        previousTransactionRCP4 = currentTransactionRCP4;
        previousRewards = currentRewards;

        for (let key in month) {
          monthTotalSum = 0;

          let proValue = key === "personal" ? 13650 : 439;
          let promoValue = key === "personal" ? 5040 : 135;
          let RCP1ProfitValue = key === "personal" ? 1000 : 100;
          let RCP1Value = key === "personal" ? 0.001 : 0.0001;
          let RCP1ToFixed = key === "personal" ? 3 : 4;
          let transactionRCP4Value = key === "personal" ? 0.001 : 0.0001;
          let transactionRCP4ToFixed = key === "personal" ? 3 : 4;
          let rewardsValue = key === "personal" ? 0.1 : 0.0125;
          let rewardsToFixed = key === "personal" ? 1 : 4;

          if (month[key].hasOwnProperty("salesPromo")) {
            Promo =
              Promo +
              (month[key].salesPromo ? month[key].salesPromo.value : 0) *
                promoValue;
          }

          if (month[key].hasOwnProperty("salesPro")) {
            Pro =
              Pro +
              (month[key].salesPro ? month[key].salesPro.value : 0) * proValue;
          }

          if (month[key].hasOwnProperty("RCP1")) {
            RCP1Profit = RCP1Profit + month[key].RCP1.sales * RCP1ProfitValue;
          }

          if (month[key].hasOwnProperty("RCP1")) {
            currentRCP1 =
              currentRCP1 +
              Number(
                (
                  month[key].RCP1.sales *
                  month[key].RCP1.averageTurnover *
                  RCP1Value
                ).toFixed(RCP1ToFixed)
              );
          }

          if (month[key].hasOwnProperty("transactionsRCP4")) {
            currentTransactionRCP4 =
              currentTransactionRCP4 +
              Number(
                (
                  month[key].transactionsRCP4.connected *
                  month[key].transactionsRCP4.averageTurnover *
                  transactionRCP4Value
                ).toFixed(transactionRCP4ToFixed)
              );
          }

          if (month[key].hasOwnProperty("rewards")) {
            let value = month[key].rewards.selectedAveragePercentageOfReward
              ? month[key].rewards.selectedAveragePercentageOfReward.value
              : 0;

            currentRewards =
              currentRewards +
              Number(
                (
                  month[key].rewards.connected *
                  month[key].rewards.averageTurnover *
                  value *
                  rewardsValue
                ).toFixed(rewardsToFixed)
              );
          }
        }

        monthTotalSum =
          Pro +
          Promo +
          RCP1Profit +
          previousRCP1 +
          previousTransactionRCP4 +
          previousRewards;

        this.barChartOptions.series[0].data[index] = monthTotalSum;
        totalSum = totalSum + monthTotalSum;
      });

      // установка значений диаграммы, если заполнены не все месяца
      if (this.months.length > 0 && this.months.length < 12) {
        for (let i = this.months.length; i < 12; i++) {
          this.barChartOptions.series[0].data[i] =
            currentRCP1 + currentTransactionRCP4 + currentRewards;

          totalSum =
            totalSum + currentRCP1 + currentTransactionRCP4 + currentRewards;
        }
      }

      this.tabs.forEach((item) => {
        if (item.id === "total") {
          item.title = totalSum > 0 ? `${totalSum.toFixed(0)} RC` : `0 RC`;
        }
      });
    },

    onResize() {
      this.innerWidth = window.innerWidth;
    },
  },

  mounted() {
    this.createUnitDataBarDiagram("personal");
    this.createUnitDataPieChart("personal");
    this.createUnitDataBarDiagram("fromNetwork");
    this.createUnitDataPieChart("fromNetwork");
    this.createTotalDataBarDiagram();
    this.createTotalDataPieChart();

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    this.setChartsOptions();
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<template>
  <div v-if="months.length > 0" class="calculator-tsp-income-total">
    <h2 class="calculator-tsp-income-total__title">Заработок за год</h2>
    <button
      class="calculator-tsp-income-total__button-reset"
      @click="$emit('resetResult')"
    >
      Сбросить расчет <span></span>
    </button>

    <MainTabs
      class="calculator-tsp-income-total__tabs"
      :selected-tab.sync="selectedTab"
      :tabs="tabs"
    />

    <MainChart
      class="calculator-tsp-income-total__pie-chart"
      :options="pieChartOptions"
    />
    <div class="calculator-tsp-income-total__chart-wrapper">
      <MainChart
        class="calculator-tsp-income-total__bar-chart"
        :options="barChartOptions"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calculator-tsp-income-total {
  &__title {
    @include title-3;
    color: $dark-primary;
    margin-bottom: 12px;
  }

  &__button-reset {
    border: none;
    padding: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    @include text-2;
    color: $dark-fourth;
    cursor: pointer;
    margin-bottom: 32px;

    span {
      mask: url("../../assets/icons/update.svg") 0 0 / cover no-repeat;
      display: inline-block;
      width: 16px;
      height: 16px;
      background: $dark-fourth;
      margin-left: $space-s;
    }

    &:hover {
      color: $dark-primary;

      span {
        background: $dark-primary;
      }
    }

    &:active {
      color: $dark-third;

      span {
        background: $dark-third;
      }
    }
  }

  &__tabs {
    max-width: 550px;
    margin-bottom: 48px;
  }

  &__pie-chart {
    height: 320px;
  }

  &__bar-chart {
    height: 300px;
    min-width: 480px;
  }

  &__chart-wrapper {
    overflow: auto;
    -ms-overflow-style: none; /* IE и Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@media (max-width: 1140px) {
  .calculator-tsp-income-total {
    &__pie-chart {
      height: 480px;
    }

    &__bar-chart {
      height: 340px;
    }
  }
}
</style>
