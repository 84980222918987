<script>
import MainInput from "@/components/helpers/MainInput";

export default {
  components: { MainInput },

  props: {
    type: {
      type: String,
      default: "personal",
    },

    fields: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      salesPromo: {
        value: "",
      },
      salesPro: {
        value: "",
      },
    };
  },

  watch: {
    salesPromo: {
      deep: true,
      handler() {
        this.$emit("changeValue", "salesPromo", this.salesPromo);
      },
    },

    salesPro: {
      deep: true,
      handler() {
        this.$emit("changeValue", "salesPro", this.salesPro);
      },
    },
  },

  computed: {
    isFilled() {
      if (!this.fields.hasOwnProperty("salesPro")) {
        return this.salesPromo.value.length > 0;
      }

      if (!this.fields.hasOwnProperty("salesPromo")) {
        return this.salesPro.value.length > 0;
      }

      return this.salesPromo.value.length > 0 && this.salesPro.value.length > 0;
    },

    currentStatus() {
      return this.isFilled ? "Заполнено" : "Не заполнено";
    },

    salesPromoProfit() {
      const value = this.type === "personal" ? 5040 : 135;

      if (this.salesPromo?.value.length === 0) {
        return 0;
      } else {
        return `${this.salesPromo.value * value} RC`;
      }
    },

    salesProProfit() {
      const value = this.type === "personal" ? 13650 : 439;

      if (this.salesPro?.value.length === 0) {
        return 0;
      } else {
        return `${this.salesPro.value * value} RC`;
      }
    },

    isShowSalesPro() {
      return this.fields.hasOwnProperty("salesPro");
    },

    isShowSalesPromo() {
      return this.fields.hasOwnProperty("salesPromo");
    },
  },

  mounted() {
    if (this.fields) {
      if (this.fields.hasOwnProperty("salesPro")) {
        this.salesPro = this.fields.salesPro;
      }

      if (this.fields.hasOwnProperty("salesPromo")) {
        this.salesPromo = this.fields.salesPromo;
      }
    }
  },
};
</script>

<template>
  <div class="block-sale-tariffs">
    <div class="block-sale-tariffs__title title">
      <h6>Продажа тарифов</h6>

      <div
        class="title__status status"
        :class="isFilled ? 'status_filled' : 'status_empty'"
      >
        <p>{{ currentStatus }}</p>
      </div>
    </div>

    <div v-if="isShowSalesPromo" class="block-sale-tariffs__wrapper">
      <MainInput
        v-model="salesPromo.value"
        class="block-sale-tariffs__input"
        :label="'Продаж Промо'"
        :type="'number'"
        :mask="'#*'"
      />

      <div v-if="salesPromoProfit" class="block-sale-tariffs__income">
        <span>Доход с продаж</span>
        <p>{{ salesPromoProfit }}</p>
      </div>
    </div>

    <div v-if="isShowSalesPro" class="block-sale-tariffs__wrapper">
      <MainInput
        v-model="salesPro.value"
        class="block-sale-tariffs__input"
        :label="'Продаж Про+'"
        :type="'number'"
        :mask="'#*'"
      />

      <div v-if="salesProProfit" class="block-sale-tariffs__income">
        <span>Доход с продаж</span>
        <p>{{ salesProProfit }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.block-sale-tariffs {
  padding: 24px;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    h6 {
      @include text-2-bold;
      color: $dark-primary;
    }

    .status {
      margin-left: 8px;
      height: 20px;
      border-radius: 10px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      @include caption-1;

      &_filled {
        background-color: $gold-main;
        color: $dark-primary;
        border: 1px solid #ffc46c;
      }

      &_empty {
        background-color: $light-third;
        color: $red;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__input {
    max-width: 220px;
  }

  .main-input {
    margin-bottom: 0 !important;
  }

  &__income {
    margin-left: 16px;

    span {
      @include text-2;
      color: $dark-sixth;
      margin-bottom: 4px;
    }

    p {
      @include body-1-bold;
      color: $dark-primary;
    }
  }
}

@media (max-width: 787px) {
  .block-sale-tariffs {
    &__input {
      max-width: 322px;
      width: 100%;
    }

    &__income {
      width: 110px;

      span {
        white-space: nowrap;
      }
    }
  }
}
</style>
