<script>
import MainInput from "@/components/helpers/MainInput";

export default {
  components: { MainInput },

  props: {
    type: {
      type: String,
      default: "personal",
    },

    fields: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      RCP1: {
        sales: "",
        averageTurnover: "",
      },
    };
  },

  watch: {
    RCP1: {
      handler() {
        this.$emit("changeValue", "RCP1", this.RCP1);
      },
      deep: true,
    },
  },

  computed: {
    isFilled() {
      return this.RCP1.sales.length > 0 && this.RCP1.averageTurnover.length > 0;
    },

    currentStatus() {
      return this.isFilled ? "Заполнено" : "Не заполнено";
    },

    salesProfit() {
      const value = this.type === "personal" ? 1000 : 100;

      if (this.RCP1.sales.length === 0) {
        return 0;
      } else {
        return `${this.RCP1.sales * value} RC`;
      }
    },

    averageTurnoverProfit() {
      const value = this.type === "personal" ? 0.001 : 0.0001;
      const fixedValue = this.type === "personal" ? 3 : 4;

      if (this.RCP1.averageTurnover.length === 0) {
        return 0;
      } else {
        return `${(this.RCP1.sales * this.RCP1.averageTurnover * value).toFixed(
          fixedValue
        )} RC`;
      }
    },
  },

  mounted() {
    if (this.fields) {
      this.RCP1 = this.fields;
    }
  },
};
</script>

<template>
  <div class="block-rcp1">
    <div class="block-rcp1__title title">
      <h6>RCP1</h6>

      <div
        class="title__status status"
        :class="isFilled ? 'status_filled' : 'status_empty'"
      >
        <p>{{ currentStatus }}</p>
      </div>
    </div>

    <div class="block-rcp1__wrapper">
      <MainInput
        v-model="RCP1.sales"
        class="block-rcp1__input"
        :label="'Продаж RCP1'"
        :type="'number'"
        :mask="'#*'"
      />

      <div v-if="salesProfit" class="block-rcp1__income">
        <span>Доход с продаж</span>
        <p>{{ salesProfit }}</p>
      </div>
    </div>

    <div class="block-rcp1__wrapper">
      <MainInput
        v-model="RCP1.averageTurnover"
        class="block-rcp1__input"
        :label="'Средний оборот на 1 ТСП'"
        :type="'number'"
      />

      <div v-if="averageTurnoverProfit" class="block-rcp1__income">
        <span>C RCP1 ежемесячно</span>
        <p>{{ averageTurnoverProfit }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.block-rcp1 {
  padding: 24px;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    h6 {
      @include text-2-bold;
      color: $dark-primary;
    }

    .status {
      margin-left: 8px;
      height: 20px;
      border-radius: 10px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      @include caption-1;

      &_filled {
        background-color: $gold-main;
        color: $dark-primary;
        border: 1px solid #ffc46c;
      }

      &_empty {
        background-color: $light-third;
        color: $red;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__input {
    max-width: 220px;
  }

  .main-input {
    margin-bottom: 0 !important;
  }

  &__income {
    margin-left: 16px;

    span {
      @include text-2;
      color: $dark-sixth;
      margin-bottom: 4px;
    }

    p {
      @include body-1-bold;
      color: $dark-primary;
    }
  }
}

@media (max-width: 787px) {
  .block-rcp1 {
    &__input {
      max-width: 322px;
      width: 100%;
    }

    &__income {
      width: 110px;
      min-width: 110px;

      span {
        white-space: nowrap;
      }
    }
  }
}
</style>
