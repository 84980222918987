<script>
import MainInput from "@/components/helpers/MainInput";

export default {
  components: { MainInput },

  props: {
    type: {
      type: String,
      default: "personal",
    },

    fields: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      transactionsRCP4: {
        connected: "",
        averageTurnover: "",
      },
    };
  },

  watch: {
    transactionsRCP4: {
      deep: true,
      handler() {
        this.$emit("changeValue", "transactionsRCP4", this.transactionsRCP4);
      },
    },
  },

  computed: {
    isFilled() {
      return (
        this.transactionsRCP4.connected.length > 0 &&
        this.transactionsRCP4.averageTurnover.length > 0
      );
    },

    currentStatus() {
      return this.isFilled ? "Заполнено" : "Не заполнено";
    },

    averageTurnoverProfit() {
      const value = this.type === "personal" ? 0.001 : 0.0001;
      const fixedValue = this.type === "personal" ? 3 : 4;

      if (
        this.transactionsRCP4.averageTurnover.length === 0 ||
        this.transactionsRCP4.connected.length === 0
      ) {
        return 0;
      } else {
        return `${(
          this.transactionsRCP4.connected *
          this.transactionsRCP4.averageTurnover *
          value
        ).toFixed(fixedValue)} RC`;
      }
    },
  },

  mounted() {
    if (this.fields) {
      this.transactionsRCP4 = this.fields;
    }
  },
};
</script>

<template>
  <div class="block-transactions-rcp4">
    <div class="block-transactions-rcp4__title title">
      <h6>С транзакций RC POS</h6>

      <div
        class="title__status status"
        :class="isFilled ? 'status_filled' : 'status_empty'"
      >
        <p>{{ currentStatus }}</p>
      </div>
    </div>

    <div class="block-transactions-rcp4__wrapper">
      <MainInput
        v-model="transactionsRCP4.connected"
        class="block-transactions-rcp4__input_short"
        :label="'Всего подключено к RC POS'"
        :type="'number'"
        :mask="'#*'"
      />
    </div>

    <div class="block-transactions-rcp4__wrapper">
      <MainInput
        v-model="transactionsRCP4.averageTurnover"
        class="block-transactions-rcp4__input"
        :label="'Средний оборот на 1 ТСП'"
        :type="'number'"
      />

      <div v-if="averageTurnoverProfit" class="block-transactions-rcp4__income">
        <span>C RC POS ежемесячно</span>
        <p>{{ averageTurnoverProfit }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.block-transactions-rcp4 {
  padding: 24px;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    h6 {
      @include text-2-bold;
      color: $dark-primary;
    }

    .status {
      margin-left: 8px;
      height: 20px;
      border-radius: 10px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      @include caption-1;

      &_filled {
        background-color: $gold-main;
        color: $dark-primary;
        border: 1px solid #ffc46c;
      }

      &_empty {
        background-color: $light-third;
        color: $red;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__input {
    max-width: 220px;
  }

  .main-input {
    margin-bottom: 0 !important;
  }

  &__income {
    margin-left: 16px;

    span {
      @include text-2;
      color: $dark-sixth;
      margin-bottom: 4px;
    }

    p {
      @include body-1-bold;
      color: $dark-primary;
    }
  }
}

@media (max-width: 787px) {
  .block-transactions-rcp4 {
    &__input {
      max-width: 322px;
      width: 100%;

      &_short {
        width: calc(100% - 126px);
        max-width: 322px;
      }
    }

    &__income {
      width: 110px;
      min-width: 110px;

      span {
        white-space: nowrap;
      }
    }
  }
}
</style>
