<script>
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  components: {
    MainButton,
  },

  methods: {
    downloadAttachment(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<template>
  <div class="calculator-tsp-income-study">
    <h4>Рассчитайте свой заработок за год</h4>

    <p>
      В этом разделе вы сможете строить оптимальные стратегии по работе с ТСП
      и рассчитывать суммы пассивного заработка
    </p>

    <div class="calculator-tsp-income-study__btns">
      <MainButton
        :title="'Инструкция к калькулятору'"
        color="gold"
        :padding="'14px 24px'"
        @click="
          downloadAttachment('./docs/Инструкция_калькулятор_дохода_от_ТСП.pdf')
        "
      />

      <MainButton
        :title="'Обучающие материалы'"
        :color="'outlined'"
        :padding="'14px 24px'"
        @click="$router.push('/documents/tsp')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calculator-tsp-income-study {
  background-color: $light-primary;
  border-radius: 16px;
  padding: 40px;
  max-width: 692px;

  h4 {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: 8px;
  }

  p {
    @include text-2;
    color: $dark-primary;
    margin-bottom: 32px;
  }

  &__btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
  }
}

@media (max-width: 576px) {
  .calculator-tsp-income-study {
    padding: 24px;
  }
}

@media (max-width: 548px) {
  .calculator-tsp-income-study {
    &__btns {
      .main-button {
        width: 100% !important;
      }
    }
  }
}
</style>
