<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },

    selectedTab: {
      type: [Object, null],
      required: true,
    },

    tabRow: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    tabWidth() {
      return 100 / this.tabs.length;
    },

    formattedTabWidth() {
      return `${this.tabWidth}%`;
    },

    innerLineIndent() {
      const tabIndex = this.tabs.findIndex((tab) => {
        return tab.id === this.selectedTab.id;
      });

      return `${tabIndex * this.tabWidth}%`;
    },
  },

  methods: {
    isActiveTab(tabId) {
      return tabId === this.selectedTab.id;
    },

    getIconTab(tab) {
      if (!tab?.notActiveIcon) return tab.icon;

      if (this.isActiveTab(tab.id)) return tab.icon;

      return tab.notActiveIcon;
    },
  },
};
</script>

<template>
  <div class="main-tabs">
    <div class="main-tabs__wrapper">
      <div
        v-for="tab in tabs"
        :key="tab.id"
        class="main-tabs__tab"
        :class="{
          'main-tabs__tab_selected': isActiveTab(tab.id),
          'main-tabs__tab_only-row': tabRow,
        }"
        :style="{ width: formattedTabWidth }"
        @click="$emit('update:selectedTab', tab)"
      >
        <span
          v-if="tab.icon"
          class="main-tabs__icon"
          :class="getIconTab(tab)"
        ></span>
        {{ tab.title }}
      </div>
    </div>

    <div class="main-tabs__line line">
      <div
        class="line__inner-line"
        :style="{ width: formattedTabWidth, left: innerLineIndent }"
      ></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-tabs {
  position: relative;

  &__wrapper {
    display: flex;
  }

  &__tab {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 4px;
    cursor: pointer;
    padding: 0 4px 16px;
    text-align: center;
    color: $light-sixth;
    width: 50%;
    @include body-1;
    transition: color 0.2s ease;

    &_selected {
      color: $dark-primary;

      span {
        background-color: $dark-primary;
      }
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    background-color: $dark-sixth;
    transition: all 0.2s ease;
  }

  .line {
    height: 3px;
    width: 100%;
    background-color: $light-fifth;
    position: relative;

    &__inner-line {
      position: relative;
      height: 100%;
      background-color: $dark-primary;
      transition: left 0.2s ease;
    }
  }
}

@media (max-width: 900px) {
  .main-tabs {
    &__tab {
      flex-direction: column;

      &_only-row {
        flex-direction: row;
      }
    }
  }
}
</style>
