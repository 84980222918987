<script>
export default {
  props: {
    type: {
      type: String,
      default: "personal",
    },

    months: {
      type: Array,
      required: true,
    },
  },

  computed: {
    RCP1Sum() {
      let RCP1 = 0;
      let value = this.type === "personal" ? 0.001 : 0.0001;
      let toFixedValue = this.type === "personal" ? 3 : 4;

      for (let key in this.months) {
        if (this.months[key][this.type]?.hasOwnProperty("RCP1")) {
          let type = this.months[key][this.type];

          RCP1 =
            RCP1 +
            Number(
              (type.RCP1.averageTurnover * value * type.RCP1.sales).toFixed(
                toFixedValue
              )
            );
        }
      }

      return RCP1;
    },

    transactionsRCP4() {
      let transactionsRCP4 = 0;
      let value = this.type === "personal" ? 0.001 : 0.0001;
      let toFixedValue = this.type === "personal" ? 3 : 4;

      for (let key in this.months) {
        if (this.months[key][this.type]?.hasOwnProperty("transactionsRCP4")) {
          let type = this.months[key][this.type];

          transactionsRCP4 =
            transactionsRCP4 +
            Number(
              (
                type.transactionsRCP4.connected *
                type.transactionsRCP4.averageTurnover *
                value
              ).toFixed(toFixedValue)
            );
        }
      }

      return transactionsRCP4;
    },

    rewards() {
      let rewards = 0;
      const value = this.type === "personal" ? 0.1 : 0.0125;
      const fixedValue = this.type === "personal" ? 1 : 4;

      for (let key in this.months) {
        if (this.months[key][this.type]?.hasOwnProperty("rewards")) {
          let type = this.months[key][this.type];

          let selectedAveragePercentageOfReward = type.rewards
            .selectedAveragePercentageOfReward
            ? type.rewards.selectedAveragePercentageOfReward.value
            : 0;

          rewards =
            rewards +
            Number(
              (
                type.rewards.connected *
                type.rewards.averageTurnover *
                selectedAveragePercentageOfReward *
                value
              ).toFixed(fixedValue)
            );
        }
      }

      return rewards;
    },
  },
};
</script>

<template>
  <div class="block-profit">
    <div class="block-profit__item item">
      <h6 class="item__title">RCP1</h6>
      <span class="item__subtitle">Ежемесячно</span>
      <p class="item__value">{{ RCP1Sum }} RC</p>
    </div>

    <div class="block-profit__item item">
      <h6 class="item__title">RC POS</h6>
      <span class="item__subtitle">Ежемесячно</span>
      <p class="item__value">{{ transactionsRCP4 }} RC</p>
    </div>

    <div class="block-profit__item item item_long">
      <h6 class="item__title">Программа лояльности</h6>
      <span class="item__subtitle">Ежемесячно</span>
      <p class="item__value">{{ rewards }} RC</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.block-profit {
  display: flex;
  padding: 24px;

  .item {
    max-width: 164px;
    width: 100%;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &__title {
      @include text-2-bold;
      color: $dark-primary;
      margin-bottom: 16px;
      white-space: nowrap;
    }

    &__subtitle {
      display: block;
      @include text-2;
      color: $dark-sixth;
      margin-bottom: 4px;
    }

    &__value {
      @include body-1-bold;
      color: $dark-primary;
    }
  }
}

@media (max-width: 576px) {
  .block-profit {
    flex-wrap: wrap;
    row-gap: 16px;

    .item {
      max-width: fit-content;

      &__title {
        margin-bottom: 8px;
      }

      &_long {
        max-width: 100%;
      }
    }
  }
}
</style>
