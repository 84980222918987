<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },

    isSelected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <button
    class="calculator-tsp-income-add-item"
    :class="{ 'calculator-tsp-income-add-item_selected': isSelected }"
    @click="$emit('selectItem')"
  >
    <p>{{ text }}</p>

    <span
      class="calculator-tsp-income-add-item__icon"
      :class="isSelected ? 'icon-minus' : 'icon-plus'"
    ></span>
  </button>
</template>

<style lang="scss" scoped>
.calculator-tsp-income-add-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: $light-primary;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &_selected {
    background-color: $gold-main;
  }

  p {
    @include text-2;
    color: $dark-primary;
  }

  &__icon {
    width: 16px;
    height: 16px;
    background-color: $dark-primary;
  }
}
</style>
