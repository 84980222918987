<script>
import MainButtonIcon from "@/package/components/MainButtonIcon";

export default {
  components: {
    MainButtonIcon,
  },

  props: {
    type: {
      type: String,
      default: "income",
    },
  },

  data() {
    return {
      isExpanded: true,
    };
  },

  computed: {
    isShowHint() {
      return this.type === "income" && !this.isExpanded;
    },
  },
};
</script>

<template>
  <div class="calculator-tsp-income-network">
    <div
      class="calculator-tsp-income-network__preview"
      :class="{
        'calculator-tsp-income-network__preview_expanded': isExpanded,
      }"
      @click="isExpanded = !isExpanded"
    >
      <div class="calculator-tsp-income-network__wrapper">
        <div class="calculator-tsp-income-network__text text">
          <span class="icon-line text__icon"></span>
          <p>От сети</p>
        </div>

        <div
          v-if="isShowHint"
          class="calculator-tsp-income-network__status status status_empty"
        >
          <p>Не заполнено</p>
        </div>
      </div>

      <MainButtonIcon class="calculator-tsp-income-network__button-arrow">
        <span
          class="icon-arrow-outline calculator-tsp-income-network__icon-arrow"
        ></span>
      </MainButtonIcon>
    </div>

    <div v-show="isExpanded" class="calculator-tsp-income-network__content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calculator-tsp-income-network {
  &__preview {
    background-color: $light-fourth;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &_expanded {
      .calculator-tsp-income-network__icon-arrow {
        transform: rotate(-90deg);
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  .text {
    display: flex;
    align-items: center;

    &__icon {
      width: 16px;
      height: 16px;
      background-color: $dark-primary;
      margin-right: 4px;
    }

    p {
      @include text-2;
      color: $dark-primary;
    }
  }

  .status {
    height: 20px;
    border-radius: 10px;
    padding: 0 6px;
    display: flex;
    align-items: center;
    background-color: $red;
    @include caption-1;
    color: $light-primary;
    margin-left: 8px;
  }

  &__icon-arrow {
    width: 16px;
    height: 16px;
    background-color: $dark-fifth;
    transform: rotate(90deg);
  }

  &__content {
    background-color: $light-primary;

    > *:not(:last-child) {
      border-bottom: 1px solid $light-sixth;
    }
  }
}
</style>
