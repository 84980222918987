<script>
import { INCOME_TYPES } from "@/package/const/calculator-tsp-income/income-types.js";
import MainModal from "@/package/components/MainModal.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import CalculatorTSPIncomeAddItem from "@/components/tsp/CalculatorTSPIncomeAddItem.vue";

export default {
  components: {
    MainModal,
    MainButton,
    CalculatorTSPIncomeAddItem,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    monthIndex: {
      type: [String, Number],
      required: true,
    },

    month: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      INCOME_TYPES: INCOME_TYPES,

      selectedIncomeTypes: {
        personal: [],
        fromNetwork: [],
      },
    };
  },

  watch: {
    month: {
      handler() {
        if (this.month) {
          for (let key in this.month) {
            for (let subKey in this.month[key]) {
              let incomeType = JSON.parse(
                JSON.stringify(
                  this.INCOME_TYPES.find((type) => type.type === subKey)
                )
              );
              this.setIncomeType(
                key,
                incomeType,
                subKey,
                this.month[key][subKey]
              );
            }
          }
        }
      },
    },
  },

  computed: {
    confirmButtonText() {
      return this.month !== null ? "Сохранить изменения" : "Добавить в расчет";
    },

    cancelButtonText() {
      return this.month !== null ? "Удалить месяц" : "Отмена";
    },

    titleText() {
      return this.month !== null
        ? `Редактирование ${this.monthIndex} месяца`
        : `Планирование ${this.monthIndex} месяца`;
    },

    isShownRepeatButton() {
      return this.monthIndex > 1;
    },

    isDisabledConfirmButton() {
      return (
        this.selectedIncomeTypes.personal.length === 0 &&
        this.selectedIncomeTypes.fromNetwork.length === 0
      );
    },
  },

  methods: {
    setIncomeType(type, incomeType, key, value) {
      const index = this.selectedIncomeTypes[type].findIndex((item) => {
        return item.id === incomeType.id;
      });

      if (index === -1) {
        this.selectedIncomeTypes[type].push(incomeType);
        this.selectedIncomeTypes[type].forEach((item) => {
          if (item.type === key) {
            item.value = value;
          }
        });
      } else {
        this.selectedIncomeTypes[type].splice(index, 1);
      }
    },

    checkIsSelected(type, incomeType) {
      return (
        this.selectedIncomeTypes[type].find((item) => {
          return item.id === incomeType.id;
        }) !== undefined
      );
    },

    confirm() {
      this.$emit("confirm", this.selectedIncomeTypes, this.monthIndex);
      this.selectedIncomeTypes = {
        personal: [],
        fromNetwork: [],
      };
    },

    cancel() {
      if (this.month !== null) {
        this.$emit("delete", this.monthIndex);
        this.selectedIncomeTypes = {
          personal: [],
          fromNetwork: [],
        };
      } else {
        this.close();
      }
    },

    close() {
      this.$emit("close");
      this.selectedIncomeTypes = {
        personal: [],
        fromNetwork: [],
      };
    },

    repeatPreviousMonth() {
      let months = JSON.parse(localStorage.getItem("months"));
      let i = this.monthIndex - 2;

      for (let key in months[i]) {
        for (let subKey in months[i][key]) {
          let incomeType = JSON.parse(
            JSON.stringify(
              this.INCOME_TYPES.find((type) => type.type === subKey)
            )
          );
          this.setIncomeType(key, incomeType);
        }
      }
    },
  },
};
</script>

<template>
  <MainModal
    class="calculator-tsp-income-modal-create"
    :show-modal="showModal"
    @close="close"
  >
    <h2 class="calculator-tsp-income-modal-create__title">
      {{ titleText }}
    </h2>

    <MainButton
      v-if="isShownRepeatButton"
      class="calculator-tsp-income-modal-create__button-recreate"
      :color="'transparent'"
      :title="'Повторить предыдущий'"
      :padding="'0 0'"
      @click="repeatPreviousMonth"
    />

    <div class="calculator-tsp-income-modal-create__wrapper">
      <div class="calculator-tsp-income-modal-create__list list">
        <div class="list__title">
          <span class="list__icon icon-person"></span>
          <p>Личные продажи</p>
        </div>

        <CalculatorTSPIncomeAddItem
          v-for="incomeType in INCOME_TYPES"
          :key="incomeType.id"
          :text="incomeType.name"
          :is-selected="checkIsSelected('personal', incomeType)"
          @selectItem="setIncomeType('personal', incomeType)"
        />
      </div>

      <div class="calculator-tsp-income-modal-create__list list">
        <div class="list__title">
          <span class="list__icon icon-binar"></span>
          <p>От сети</p>
        </div>

        <CalculatorTSPIncomeAddItem
          v-for="incomeType in INCOME_TYPES"
          :key="incomeType.id"
          :text="incomeType.name"
          :is-selected="checkIsSelected('fromNetwork', incomeType)"
          @selectItem="setIncomeType('fromNetwork', incomeType)"
        />
      </div>
    </div>

    <div class="calculator-tsp-income-modal-create__buttons">
      <MainButton
        :title="confirmButtonText"
        :color="'dark'"
        :padding="'14px 24px'"
        :disabled="isDisabledConfirmButton"
        @click="confirm"
      />
      <MainButton
        :title="cancelButtonText"
        :color="'outlined'"
        :padding="'14px 24px'"
        @click="cancel"
      />
    </div>
  </MainModal>
</template>

<style lang="scss" scoped>
.calculator-tsp-income-modal-create {
  &__title {
    @include title-3;
    color: $dark-primary;
    margin-bottom: 8px;
  }

  &__button-recreate {
    color: $blue;
  }

  &__wrapper {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 320px 320px;
    column-gap: 40px;
  }

  .list {
    &__title {
      margin-bottom: 16px;
      @include text-2;
      color: $dark-primary;
      display: flex;
      align-items: center;
    }

    &__icon {
      width: 16px;
      height: 16px;
      background-color: $dark-primary;
      margin-right: 4px;
    }
  }

  &__buttons {
    display: flex;
    gap: 16px;
    margin-top: 48px;
  }
}

@media (max-width: 769px) {
  .calculator-tsp-income-modal-create {
    padding: 0;

    ::v-deep .main-modal {
      &__container {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
    }

    ::v-deep .icon-close {
      display: none;
    }

    &__wrapper {
      grid-template-columns: 100%;
      row-gap: 24px;
    }

    &__buttons {
      flex-direction: column;
      gap: 8px;
    }
  }
}
</style>
