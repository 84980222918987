<script>
import { AVERAGE_PERCENTAGES_OF_REWARDS } from "@/package/const/calculator-tsp-income/average-percentages-of-rewards";
import MainInput from "@/components/helpers/MainInput";
import MainSelect from "@/components/helpers/MainSelect";

export default {
  components: { MainSelect, MainInput },

  props: {
    type: {
      type: String,
      default: "personal",
    },

    fields: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      rewards: {
        connected: "",
        averageTurnover: "",
        selectedAveragePercentageOfReward: null,
      },

      AVERAGE_PERCENTAGES_OF_REWARDS: AVERAGE_PERCENTAGES_OF_REWARDS,
    };
  },

  watch: {
    rewards: {
      handler() {
        this.$emit("changeValue", "rewards", this.rewards);
      },
      deep: true,
    },
  },

  computed: {
    isFilled() {
      return (
        this.rewards.connected.length > 0 &&
        this.rewards.averageTurnover.length > 0
      );
    },

    currentStatus() {
      return this.isFilled ? "Заполнено" : "Не заполнено";
    },

    averageTurnoverProfit() {
      const value = this.type === "personal" ? 0.1 : 0.0125;
      const fixedValue = this.type === "personal" ? 1 : 4;

      if (
        this.rewards.averageTurnover.length === 0 ||
        this.rewards.connected.length === 0
      ) {
        return 0;
      } else {
        return `${(
          this.rewards.connected *
          this.rewards.averageTurnover *
          this.rewards.selectedAveragePercentageOfReward?.value *
          value
        ).toFixed(fixedValue)} RC`;
      }
    },
  },

  mounted() {
    this.rewards.selectedAveragePercentageOfReward = this.AVERAGE_PERCENTAGES_OF_REWARDS[4];

    if (this.fields) {
      this.rewards = this.fields;
    }
  },
};
</script>

<template>
  <div class="block-rewards">
    <div class="block-rewards__title title">
      <h6>C транзакций по программе лояльности</h6>

      <div
        class="title__status status"
        :class="isFilled ? 'status_filled' : 'status_empty'"
      >
        <p>{{ currentStatus }}</p>
      </div>
    </div>

    <div class="block-rewards__wrapper">
      <MainInput
        v-model="rewards.connected"
        class="block-rewards__input"
        :label="'Всего подключено ТСП'"
        :type="'number'"
        :mask="'#*'"
      />
    </div>

    <div class="block-rewards__wrapper">
      <MainSelect
        class="block-rewards__selector"
        :title="'Средний % вознаграждения'"
        :selected-option.sync="rewards.selectedAveragePercentageOfReward"
        :options="AVERAGE_PERCENTAGES_OF_REWARDS"
        :style-type="'background-gray'"
      />

      <MainInput
        v-model="rewards.averageTurnover"
        class="block-rewards__input"
        :label="'Средний оборот на 1 ТСП'"
        :type="'number'"
      />

      <div v-if="averageTurnoverProfit" class="block-rewards__income">
        <span>Вознаграждений ежемесячно</span>
        <p>{{ averageTurnoverProfit }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.block-rewards {
  padding: 24px;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    h6 {
      @include text-2-bold;
      color: $dark-primary;
    }

    .status {
      margin-left: 8px;
      height: 20px;
      border-radius: 10px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      @include caption-1;

      &_filled {
        background-color: $gold-main;
        color: $dark-primary;
        border: 1px solid #ffc46c;
      }

      &_empty {
        background-color: $light-third;
        color: $red;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__input {
    max-width: 220px;
  }

  .main-input {
    margin-bottom: 0 !important;
  }

  &__selector {
    max-width: 220px;
    width: 100%;
    margin-right: 16px;

    &::v-deep {
      .main-select__items {
        top: auto;
        bottom: calc(100% + 8px);
      }
    }
  }

  &__income {
    margin-left: 16px;

    span {
      @include text-2;
      color: $dark-sixth;
      margin-bottom: 4px;
    }

    p {
      @include body-1-bold;
      color: $dark-primary;
    }
  }
}

@media (max-width: 787px) {
  .block-rewards {
    &__income {
      width: 110px;
    }

    &__wrapper {
      flex-wrap: wrap;
    }

    &__input {
      max-width: 322px;
      width: calc(100% - 126px);
    }

    &__selector {
      max-width: 322px;
      width: calc(100% - 126px);
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}
</style>
