<script>
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";
import CalculatorTSPIncomePersonal from "@/components/tsp/CalculatorTSPIncomePersonal";
import CalculatorTSPIncomeNetwork from "@/components/tsp/CalculatorTSPIncomeNetwork";
import BlockProfit from "@/components/tsp/BlockProfit";

export default {
  components: {
    BlockProfit,
    CalculatorTSPIncomeNetwork,
    CalculatorTSPIncomePersonal,
    MainButtonIcon,
  },

  data() {
    return {
      isExpanded: false,
      personalTotalSum: 0,
      networkTotalSum: 0,
      allTotalSum: 0,
    };
  },

  props: {
    months: {
      type: Array,
      required: true,
    },
  },

  computed: {
    personalSum() {
      let personalTotalSum = 0;

      for (let key in this.months) {
        if (this.months[key].hasOwnProperty("personal")) {
          let personal = this.months[key].personal;
          let RCP1 = 0;
          let transactionsRCP4 = 0;
          let rewards = 0;

          if (personal.hasOwnProperty("RCP1")) {
            RCP1 = Number(
              (
                personal.RCP1.sales *
                personal.RCP1.averageTurnover *
                0.001
              ).toFixed(3)
            );
          }

          if (personal.hasOwnProperty("transactionsRCP4")) {
            transactionsRCP4 = (
              personal.transactionsRCP4.connected *
              personal.transactionsRCP4.averageTurnover *
              0.001
            ).toFixed(3);
          }

          if (personal.hasOwnProperty("rewards")) {
            let value = personal.rewards.selectedAveragePercentageOfReward
              ? personal.rewards.selectedAveragePercentageOfReward.value
              : 0;
            rewards = (
              personal.rewards.connected *
              personal.rewards.averageTurnover *
              value *
              0.1
            ).toFixed(1);
          }

          personalTotalSum =
            personalTotalSum +
            Number(RCP1 + Number(transactionsRCP4) + Number(rewards));
        }
      }

      return personalTotalSum > 0 ? Number(personalTotalSum.toFixed(3)) : 0;
    },

    networkSum() {
      let networkTotalSum = 0;

      for (let key in this.months) {
        if (this.months[key].hasOwnProperty("fromNetwork")) {
          let network = this.months[key].fromNetwork;
          let RCP1 = 0;
          let transactionsRCP4 = 0;
          let rewards = 0;

          if (network.hasOwnProperty("RCP1")) {
            RCP1 = Number(
              (
                network.RCP1.sales *
                network.RCP1.averageTurnover *
                0.0001
              ).toFixed(4)
            );
          }

          if (network.hasOwnProperty("transactionsRCP4")) {
            transactionsRCP4 = (
              network.transactionsRCP4.connected *
              network.transactionsRCP4.averageTurnover *
              0.0001
            ).toFixed(4);
          }

          if (network.hasOwnProperty("rewards")) {
            let value = network.rewards.selectedAveragePercentageOfReward
              ? network.rewards.selectedAveragePercentageOfReward.value
              : 0;

            rewards = (
              network.rewards.connected *
              network.rewards.averageTurnover *
              value *
              0.0125
            ).toFixed(4);
          }

          networkTotalSum =
            networkTotalSum +
            Number(RCP1 + Number(transactionsRCP4) + Number(rewards));
        }
      }

      return networkTotalSum > 0 ? Number(networkTotalSum.toFixed(4)) : 0;
    },

    totalSum() {
      if (this.networkSum === 0 && this.personalSum === 0) {
        return 0;
      } else if (this.networkSum === 0) {
        return this.personalSum;
      } else if (this.personalSum === 0) {
        return this.networkSum;
      }

      return Number(this.personalSum) + Number(this.networkSum);
    },
  },
};
</script>

<template>
  <div
    class="calculator-tsp-income-month"
    :class="{ 'calculator-tsp-income-month_expanded': isExpanded }"
  >
    <div class="calculator-tsp-income-month__summary summary">
      <div class="summary__info info">
        <div class="info__month">
          <p>Каждый месяц далее</p>
        </div>

        <div class="info__total total">
          <div class="total__item">
            <span class="icon-person total__icon"></span>
            <p>{{ personalSum }} RC</p>
          </div>
          <div class="total__item">
            <span class="icon-line total__icon"></span>
            <p>{{ networkSum }} RC</p>
          </div>
          <div class="total__item total__item_without-icon">
            <span>Итог</span>
            <p>{{ totalSum }} RC</p>
          </div>
        </div>
      </div>

      <MainButtonIcon
        class="summary__button-arrow"
        @click="isExpanded = !isExpanded"
      >
        <span class="icon-arrow-outline summary__icon-arrow"></span>
      </MainButtonIcon>
    </div>

    <CalculatorTSPIncomePersonal v-if="isExpanded" :type="'profit'">
      <BlockProfit :months="months" />
    </CalculatorTSPIncomePersonal>

    <CalculatorTSPIncomeNetwork v-if="isExpanded" :type="'profit'">
      <BlockProfit :type="'fromNetwork'" :months="months" />
    </CalculatorTSPIncomeNetwork>
  </div>
</template>

<style lang="scss" scoped>
.calculator-tsp-income-month {
  border-radius: 8px;

  > *:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
  }

  > *:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow: hidden;
  }

  &_expanded {
    .summary__icon-arrow {
      transform: rotate(-90deg) !important;
    }
  }

  .summary {
    background-color: $dark-third;
    padding: 24px;
    display: flex;
    align-items: center;

    &__button-arrow {
      height: 40px;
      flex: 1;
      margin-left: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__icon-arrow {
      width: 16px;
      height: 16px;
      background-color: $light-fifth;
      transform: rotate(90deg);
    }
  }

  .info {
    &__month {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      p {
        @include text-2-bold;
        color: $light-primary;
      }
    }

    &__button-change {
      color: $blue;
      margin-left: 8px;
    }
  }

  .total {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;

    &__icon {
      width: 16px;
      height: 16px;
      background-color: $light-sixth;
    }

    &__item {
      @include text-2;
      color: $light-primary;
      display: flex;
      align-items: center;

      span {
        margin-right: 4px;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }

      &_without-icon {
        span {
          color: $light-sixth;
        }
      }
    }
  }
}
</style>
