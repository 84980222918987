<script>
import CalculatorTSPIncomeStudy from "@/components/tsp/CalculatorTSPIncomeStudy.vue";
import CalculatorTSPIncomeAddMonth from "@/components/tsp/CalculatorTSPIncomeAddMonth.vue";
import CalculatorTSPIncomeModalCreate from "@/components/tsp/CalculatorTSPIncomeModalCreate.vue";
import CalculatorTSPIncomeMonth from "@/components/tsp/CalculatorTSPIncomeMonth.vue";
import CalculatorTSPIncomeTotal from "@/components/tsp/CalculatorTSPIncomeTotal";
import CalculatorTSPIncomeMonthProfit from "@/components/tsp/CalculatorTSPIncomeMonthProfit";

export default {
  components: {
    CalculatorTSPIncomeMonthProfit,
    CalculatorTSPIncomeTotal,
    CalculatorTSPIncomeStudy,
    CalculatorTSPIncomeAddMonth,
    CalculatorTSPIncomeModalCreate,
    CalculatorTSPIncomeMonth,
  },

  data() {
    return {
      isShownModalCreate: false,
      months: [],
      indexCreatedMonth: 1,
      editMonth: null,
    };
  },

  computed: {
    isShownAddMonth() {
      return this.months.length <= 11;
    },

    showedMonthProfit() {
      for (let key in this.months) {
        if (
          this.months[key].personal?.hasOwnProperty("RCP1") ||
          this.months[key].personal?.hasOwnProperty("transactionsRCP4") ||
          this.months[key].personal?.hasOwnProperty("rewards")
        )
          return true;

        if (
          this.months[key].fromNetwork?.hasOwnProperty("RCP1") ||
          this.months[key].fromNetwork?.hasOwnProperty("transactionsRCP4") ||
          this.months[key].fromNetwork?.hasOwnProperty("rewards")
        )
          return true;
      }

      return false;
    },
  },

  methods: {
    addMonth(selectedIncomeTypes, monthIndex) {
      this.isShownModalCreate = false;

      let month = {};

      if (selectedIncomeTypes.personal.length > 0) {
        month.personal = {};

        selectedIncomeTypes.personal.forEach((incomeType) => {
          if (month.personal[incomeType.type] === undefined) {
            month.personal[incomeType.type] = {};
          }

          Object.assign(month.personal[incomeType.type], incomeType.value);
        });
      }

      if (selectedIncomeTypes.fromNetwork.length > 0) {
        month.fromNetwork = {};

        selectedIncomeTypes.fromNetwork.forEach((incomeType) => {
          if (month.fromNetwork[incomeType.type] === undefined) {
            month.fromNetwork[incomeType.type] = {};
          }

          Object.assign(month.fromNetwork[incomeType.type], incomeType.value);
        });
      }

      if (this.months.length >= monthIndex) {
        // this.months[monthIndex - 1] = month;
        let months = JSON.parse(localStorage.getItem("months"));
        months[monthIndex - 1] = Object.assign({}, month);
        this.months = months;
      } else {
        this.months.push(month);
      }

      localStorage.setItem("months", JSON.stringify(this.months));
    },

    deleteMonth(monthIndex) {
      this.months.splice(monthIndex - 1, 1);
      this.editMonth = null;
      this.indexCreatedMonth = this.months.length + 1;
      this.isShownModalCreate = false;

      localStorage.setItem("months", JSON.stringify(this.months));
    },

    changeMonthValues(monthIndex, month) {
      let months = JSON.parse(localStorage.getItem("months"));
      months[monthIndex] = Object.assign({}, month);
      this.months = months;

      localStorage.setItem("months", JSON.stringify(this.months));
    },

    resetMonths() {
      this.months = [];
      this.indexCreatedMonth = 1;

      localStorage.removeItem("months");
    },

    createAddMonthModal() {
      if (this.months.length !== 0) {
        this.indexCreatedMonth = this.months.length + 1;
      }
      this.isShownModalCreate = true;
    },

    createChangeMonthModal(monthIndex, month) {
      this.editMonth = month;
      this.indexCreatedMonth = monthIndex;
      this.isShownModalCreate = true;
    },

    closeCreateModal() {
      this.editMonth = null;
      this.isShownModalCreate = false;
    },
  },

  mounted() {
    localStorage.getItem("months") !== null
      ? (this.months = JSON.parse(localStorage.getItem("months")))
      : (this.months = []);
  },
};
</script>

<template>
  <div class="calculator-tsp-income default-content-padding">
    <CalculatorTSPIncomeStudy />

    <div class="calculator-tsp-income__content">
      <CalculatorTSPIncomeMonth
        v-for="(month, index) in months"
        :key="index"
        :month="month"
        :month-index="index"
        class="calculator-tsp-income__month"
        @changeMonthOptions="createChangeMonthModal"
        @changeMonthValue="changeMonthValues"
      />

      <CalculatorTSPIncomeMonthProfit
        v-if="showedMonthProfit"
        class="calculator-tsp-income__month"
        :months="months"
      />

      <CalculatorTSPIncomeAddMonth
        v-if="isShownAddMonth"
        @click="createAddMonthModal"
      />

      <CalculatorTSPIncomeTotal
        class="calculator-tsp-income__total"
        :months="months"
        @resetResult="resetMonths"
      />
    </div>

    <CalculatorTSPIncomeModalCreate
      :show-modal="isShownModalCreate"
      :month-index="indexCreatedMonth"
      :month="editMonth"
      @confirm="addMonth"
      @close="closeCreateModal"
      @delete="deleteMonth"
    />
  </div>
</template>

<style lang="scss" scoped>
.calculator-tsp-income {
  padding-bottom: 48px;

  &__content {
    max-width: 800px;
    margin-top: 48px;
  }

  &__month {
    &:first-child {
      margin-top: 48px;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__total {
    margin-top: 48px;
  }
}
</style>
