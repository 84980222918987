<script>
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import CalculatorTSPIncomePersonal from "@/components/tsp/CalculatorTSPIncomePersonal";
import CalculatorTSPIncomeNetwork from "@/components/tsp/CalculatorTSPIncomeNetwork";
import BlockRewards from "@/components/tsp/BlockRewards";
import BlockTransactionsRCP4 from "@/components/tsp/BlockTransactionsRCP4";
import BlockRCP1 from "@/components/tsp/BlockRCP1";
import BlockSaleTariffs from "@/components/tsp/BlockSaleTariffs";

export default {
  components: {
    CalculatorTSPIncomeNetwork,
    CalculatorTSPIncomePersonal,
    MainButtonIcon,
    MainButton,
    BlockRewards,
    BlockTransactionsRCP4,
    BlockRCP1,
    BlockSaleTariffs,
  },

  data() {
    return {
      isExpanded: true,

      createdMonth: {},
    };
  },

  props: {
    month: {
      type: Object,
      required: true,
    },

    monthIndex: {
      type: Number,
      required: true,
    },
  },

  computed: {
    isShownPersonalSales() {
      return (
        this.month.hasOwnProperty("personal") &&
        (this.month.personal.hasOwnProperty("salesPromo") ||
          this.month.personal.hasOwnProperty("salesPro"))
      );
    },

    personalSalesFields() {
      let fields = {};

      if (
        this.month.hasOwnProperty("personal") &&
        this.month.personal.hasOwnProperty("salesPromo")
      ) {
        fields.salesPromo = this.month.personal.salesPromo;
      }

      if (
        this.month.hasOwnProperty("personal") &&
        this.month.personal.hasOwnProperty("salesPro")
      ) {
        fields.salesPro = this.month.personal.salesPro;
      }

      return fields;
    },

    isShownNetworkSales() {
      return (
        this.month.hasOwnProperty("fromNetwork") &&
        (this.month.fromNetwork.hasOwnProperty("salesPromo") ||
          this.month.fromNetwork.hasOwnProperty("salesPro"))
      );
    },

    networkSalesFields() {
      let fields = {};

      if (
        this.month.hasOwnProperty("fromNetwork") &&
        this.month.fromNetwork.hasOwnProperty("salesPromo")
      ) {
        fields.salesPromo = this.month.fromNetwork.salesPromo;
      }

      if (
        this.month.hasOwnProperty("fromNetwork") &&
        this.month.fromNetwork.hasOwnProperty("salesPro")
      ) {
        fields.salesPro = this.month.fromNetwork.salesPro;
      }

      return fields;
    },

    isFilledPersonal() {
      let type = "";
      let personal = this.month.personal ? this.month.personal : "";

      for (let key in personal) {
        for (let subKey in personal[key]) {
          if (personal[key][subKey] === "") {
            type = "income";
          }
        }
      }

      return type;
    },

    isFilledNetwork() {
      let type = "";
      let network = this.month.fromNetwork ? this.month.fromNetwork : "";

      for (let key in network) {
        for (let subKey in network[key]) {
          if (network[key][subKey] === "") {
            type = "income";
          }
        }
      }

      return type;
    },

    isFilledAll() {
      return (
        !this.isExpanded &&
        (this.isFilledNetwork === "income" ||
          this.isFilledPersonal === "income")
      );
    },

    showPersonal() {
      return (
        this.isExpanded &&
        this.month.hasOwnProperty("personal") &&
        Object.keys(this.month.personal).length > 0
      );
    },

    showNetwork() {
      return (
        this.isExpanded &&
        this.month.hasOwnProperty("fromNetwork") &&
        Object.keys(this.month.fromNetwork).length > 0
      );
    },

    personalSum() {
      if (this.month.hasOwnProperty("personal")) {
        let personal = JSON.parse(JSON.stringify(this.month.personal));
        let salesPromo = 0;
        let salesPro = 0;
        let RCP1 = 0;

        if (personal.hasOwnProperty("salesPromo")) {
          salesPromo =
            (personal.salesPromo ? personal.salesPromo.value : 0) * 5040;
        }

        if (personal.hasOwnProperty("salesPro")) {
          salesPro = (personal.salesPro ? personal.salesPro.value : 0) * 13650;
        }

        if (personal.hasOwnProperty("RCP1")) {
          RCP1 = Number(personal.RCP1.sales * 1000);
        }

        return salesPromo + salesPro + RCP1;
      }

      return 0;
    },

    networkSum() {
      if (this.month.hasOwnProperty("fromNetwork")) {
        let network = JSON.parse(JSON.stringify(this.month.fromNetwork));
        let salesPromo = 0;
        let salesPro = 0;
        let RCP1 = 0;

        if (network.hasOwnProperty("salesPromo")) {
          salesPromo =
            (network.salesPromo ? network.salesPromo.value : 0) * 135;
        }

        if (network.hasOwnProperty("salesPro")) {
          salesPro = (network.salesPro ? network.salesPro.value : 0) * 439;
        }

        if (network.hasOwnProperty("RCP1")) {
          RCP1 = Number(network.RCP1.sales * 100);
        }

        return salesPromo + salesPro + RCP1;
      }

      return 0;
    },

    totalSum() {
      if (this.personalSum === 0 && this.networkSum === 0) {
        return 0;
      } else if (this.networkSum === 0) {
        return this.personalSum;
      } else if (this.personalSum === 0) {
        return this.networkSum;
      }

      return Number(this.personalSum) + Number(this.networkSum);
    },
  },

  methods: {
    changePersonal(key, values) {
      this.createdMonth.personal = {};

      if (!this.month.hasOwnProperty("fromNetwork")) {
        this.createdMonth.fromNetwork = {};
      }

      if (this.month.hasOwnProperty("personal")) {
        this.createdMonth.personal = JSON.parse(
          JSON.stringify(this.month.personal)
        );
      }

      this.createdMonth.personal[key] = values;

      this.$emit("changeMonthValue", this.monthIndex, this.createdMonth);
    },

    changeNetwork(key, values) {
      this.createdMonth.fromNetwork = {};

      if (!this.month.hasOwnProperty("personal")) {
        this.createdMonth.personal = {};
      }

      if (this.month.hasOwnProperty("fromNetwork")) {
        this.createdMonth.fromNetwork = JSON.parse(
          JSON.stringify(this.month.fromNetwork)
        );
      }

      this.createdMonth.fromNetwork[key] = values;

      this.$emit("changeMonthValue", this.monthIndex, this.createdMonth);
    },
  },

  mounted() {
    if (this.month.hasOwnProperty("fromNetwork")) {
      this.createdMonth.fromNetwork = {};

      for (let key in this.month.fromNetwork) {
        this.createdMonth.fromNetwork[key] = this.month.fromNetwork[key];
      }

      this.$emit("changeMonthValue", this.monthIndex, this.createdMonth);
    }

    if (this.month.hasOwnProperty("personal")) {
      this.createdMonth.personal = {};

      for (let key in this.month.personal) {
        this.createdMonth.personal[key] = this.month.personal[key];
      }

      this.$emit("changeMonthValue", this.monthIndex, this.createdMonth);
    }
  },
};
</script>

<template>
  <div
    class="calculator-tsp-income-month"
    :class="{ ' calculator-tsp-income-month_expanded': isExpanded }"
  >
    <div class="calculator-tsp-income-month__summary summary">
      <div class="summary__info info">
        <div class="info__month">
          <p>{{ monthIndex + 1 }} месяц</p>

          <MainButton
            class="info__button-change"
            :color="'transparent'"
            :title="'Изменить'"
            :padding="'0 0'"
            @click="$emit('changeMonthOptions', monthIndex + 1, month)"
          />
        </div>

        <div class="info__total total">
          <div class="total__item">
            <span class="icon-person total__icon"></span>
            <p>{{ personalSum }} RC</p>
          </div>
          <div class="total__item">
            <span class="icon-line total__icon"></span>
            <p>{{ networkSum }} RC</p>
          </div>
          <div class="total__item total__item_without-icon">
            <span>Итог</span>
            <p>{{ totalSum }} RC</p>
          </div>

          <div v-if="isFilledAll" class="total__status">
            <p>Не заполнено</p>
          </div>
        </div>
      </div>

      <MainButtonIcon
        class="summary__button-arrow"
        @click="isExpanded = !isExpanded"
      >
        <span class="icon-arrow-outline summary__icon-arrow"></span>
      </MainButtonIcon>
    </div>

    <CalculatorTSPIncomePersonal v-show="showPersonal" :type="isFilledPersonal">
      <BlockSaleTariffs
        v-if="isShownPersonalSales"
        :fields="personalSalesFields"
        @changeValue="changePersonal"
      />

      <BlockRCP1
        v-if="month.personal && month.personal.RCP1"
        :fields="month.personal.RCP1"
        @changeValue="changePersonal"
      />

      <BlockTransactionsRCP4
        v-if="month.personal && month.personal.transactionsRCP4"
        :fields="month.personal.transactionsRCP4"
        @changeValue="changePersonal"
      />

      <BlockRewards
        v-if="month.personal && month.personal.rewards"
        :fields="month.personal.rewards"
        @changeValue="changePersonal"
      />
    </CalculatorTSPIncomePersonal>

    <CalculatorTSPIncomeNetwork v-show="showNetwork" :type="isFilledNetwork">
      <BlockSaleTariffs
        v-if="isShownNetworkSales"
        :fields="networkSalesFields"
        :type="'network'"
        @changeValue="changeNetwork"
      />

      <BlockRCP1
        v-if="month.fromNetwork && month.fromNetwork.RCP1"
        :type="'network'"
        :fields="month.fromNetwork.RCP1"
        @changeValue="changeNetwork"
      />

      <BlockTransactionsRCP4
        v-if="month.fromNetwork && month.fromNetwork.transactionsRCP4"
        :type="'network'"
        :fields="month.fromNetwork.transactionsRCP4"
        @changeValue="changeNetwork"
      />

      <BlockRewards
        v-if="month.fromNetwork && month.fromNetwork.rewards"
        :type="'network'"
        :fields="month.fromNetwork.rewards"
        @changeValue="changeNetwork"
      />
    </CalculatorTSPIncomeNetwork>
  </div>
</template>

<style lang="scss" scoped>
.calculator-tsp-income-month {
  border-radius: 8px;
  overflow: hidden;

  // > *:first-child {
  //   border-top-right-radius: 8px;
  //   border-top-left-radius: 8px;
  //   overflow: hidden;
  // }

  // > *:last-child {
  //   border-bottom-right-radius: 8px;
  //   border-bottom-left-radius: 8px;
  //   overflow: hidden;
  // }

  &_expanded {
    .summary__icon-arrow {
      transform: rotate(-90deg) !important;
    }
  }

  .summary {
    background-color: $dark-third;
    padding: 24px;
    display: flex;
    align-items: center;

    &__button-arrow {
      height: 40px;
      flex: 1;
      margin-left: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__icon-arrow {
      width: 16px;
      height: 16px;
      background-color: $light-fifth;
      transform: rotate(90deg);
    }
  }

  .info {
    &__month {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      p {
        @include text-2-bold;
        color: $light-primary;
      }
    }

    &__button-change {
      color: $blue;
      margin-left: 8px;
    }
  }

  .total {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;

    &__icon {
      width: 16px;
      height: 16px;
      background-color: $light-sixth;
    }

    &__item {
      @include text-2;
      color: $light-primary;
      display: flex;
      align-items: center;

      span {
        margin-right: 4px;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }

      &_without-icon {
        span {
          color: $light-sixth;
        }
      }
    }

    &__status {
      height: 20px;
      border-radius: 10px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      background-color: $red;
      @include caption-1;
      color: $light-primary;
    }
  }
}
</style>
